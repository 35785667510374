import React from "react";
import PropTypes from "prop-types";
import addTargetBlank from "../libs/add-target-blank";
import addBreaks from "../libs/add-beraks";

export const HTMLContent = ({ content, className }) => {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: addBreaks(addTargetBlank(content)) }}
    />
  );
};

const Content = ({ content, className }) => (
  <div className={className}>{content}</div>
);

Content.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string
};

HTMLContent.propTypes = Content.propTypes;

export default Content;
